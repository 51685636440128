export class SwitchCity {
    constructor() {
        this.switchCityInit();
    }
    addBindToButton(form) {
        const btnSwitchCity = form.querySelector('[data-entity="btn-switch-city"]');
        const firstCity = form.querySelector('.text-field__input[name="first-city"]');
        const secondCity = form.querySelector('.text-field__input[name="second-city"]');

        if (btnSwitchCity) {
            btnSwitchCity.addEventListener('click', () => {
                [firstCity.value, secondCity.value] = [secondCity.value, firstCity.value];
            });
        }
    }
    switchCityInit() {
        const form = document.querySelector('.form[name="send"]');

        if (form) {
            this.addBindToButton(form);
        }
    }
}
