export class BurgerMenu {
    constructor() {
        this.initBurgerMenu();
    }

    initBurgerMenu() {
        const burgerMenu = document.querySelector('.burger-menu');

        if (burgerMenu) {
            this.addListenerToBurgerButton(burgerMenu);
            this.addListenerToTriggerLink(burgerMenu);
        }
    }

    triggerLink(item) {
        const title = item.querySelector('.burger-menu__title');
        const menu = item.querySelector('.burger-menu__sublist');

        title.classList.toggle('burger-menu__title--red');
        menu.classList.toggle('visually-hidden');
    }

    addListenerToTriggerLink(burgerMenu) {
        const triggers = burgerMenu.querySelectorAll('.burger-menu__trigger');

        if (triggers) {
            triggers.forEach((item) => {
                item.addEventListener('click', () => {
                    this.triggerLink(item);
                });
            });
        }
    }
    addListenerToBurgerButton(burgerMenu) {
        const closeBtn = burgerMenu.querySelector('.burger-menu__cross');
        const openBtn = document.querySelector('[data-entity="btn-burger-trigger"]');
        const window = document.querySelector('.base');
        const blackScreen = document.querySelector('[data-entity="black-screen-trigger"]');

        if (openBtn && closeBtn) {
            openBtn.addEventListener('click', () => {
                window.classList.add('scroll-lock');
                blackScreen.classList.remove('visually-hidden');
                burgerMenu.classList.add('burger-menu--transform');
            });
            closeBtn.addEventListener('click', () => {
                burgerMenu.classList.remove('burger-menu--transform');
                blackScreen.classList.add('visually-hidden');
                window.classList.remove('scroll-lock');
            });
        }

        blackScreen?.addEventListener('click', () => {
            closeBtn?.click();
        });
    }
}
