export class Widget {
    constructor() {
        this.initWidget();
    }

    initWidget() {
        this.widget = document.querySelector('.widget');

        if (this.widget) {
            this.addListenerToWidget();
        }
    }

    addListenerToWidget() {
        const trigger = this.widget.querySelector('[data-entity="btn-widget-trigger"]');
        const widgetMenu = this.widget.querySelector('.widget__menu');

        if (!trigger || !widgetMenu) return;

        trigger.addEventListener('click', () => widgetMenu.classList.toggle('visually-hidden'));
    }
}
