export class Promo {
    #maxAge = 86400;

    constructor() {
        this.promo = document.querySelector('.promo');
        this.header = document.querySelector('.header');

        if (!this.promo) return;

        this.initClosePromo();
    }

    initClosePromo() {
        const closeTrigger = this.promo.querySelector('.promo__cross');

        closeTrigger.addEventListener('click', () => {
            this.promo.remove();
            this.header.classList.remove('header--with-promo');
            this.setCookie();
        });
    }

    setCookie() {
        document.cookie = `cookiePromo=hidden; path=/; max-age=${this.#maxAge}`;
    }
}
