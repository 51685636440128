const maxAge = 86400;

const removeCookieAcceptNotice = (currentTarget) => {
    const notice = currentTarget.closest('.cookie');

    if (notice) {
        notice.remove();
    }
};

const setCookie = (evt) => {
    removeCookieAcceptNotice(evt.currentTarget);

    document.cookie = `cookieAccept=accept; path=/; max-age=${maxAge}`;
};

const cookieAcceptHandler = () => {
    const buttonElement = document.querySelector('.cookie__btn-accept');

    if (buttonElement) {
        buttonElement.addEventListener('click', setCookie);
    }
};

const closeCookieAcceptNotice = () => {
    const buttonClose = document.querySelector('.cookie__btn-close');
    const notice = document.querySelector('.cookie');

    if (buttonClose) {
        buttonClose.addEventListener('click', () => {
            notice.style.display = 'none';
        });
    }
};

export {cookieAcceptHandler};
export {closeCookieAcceptNotice};
