import './styles/index.css';

import {BurgerMenu} from './scripts/components/burger-menu';
import {Widget} from './scripts/components/widget';
import {Mask} from './scripts/components/form-utils/mask';
import {SwitchCity} from './scripts/components/form-utils/switch-city';
import {Promo} from './scripts/components/promo';
import {cookieAcceptHandler, closeCookieAcceptNotice} from './scripts/components/cookie';

document.addEventListener('DOMContentLoaded', async () => {
    if (document.querySelector('[data-entity=index]')) {
        const {IndexControl} = await import('./scripts/components/index-control');
        new IndexControl();
    }
    if (document.querySelector('[data-entity="contacts"]')) {
        const {QuestionForm} = await import('./scripts/components/question-form');
        new QuestionForm();
        const {OfficesList} = await import('./scripts/components/offices-list');
        new OfficesList();
    }
    if (document.querySelector('[data-entity="help"]')) {
        const {ArticlesSearch} = await import('./scripts/components/articles-search');
        new ArticlesSearch();
        const {QuestionForm} = await import('./scripts/components/question-form');
        new QuestionForm();
        const {ArticleVote} = await import('./scripts/components/article-vote');
        new ArticleVote();
    }

    if (document.querySelector('[data-entity="tracking"]')) {
        const {dropDownSwitchInit} = await import('./scripts/components/drop-down-switch');
        dropDownSwitchInit();

        const {trackingInit} = await import('./scripts/components/tracking');
        trackingInit();
    }

    new BurgerMenu();
    new Widget();
    new Mask();
    new SwitchCity();
    new Promo();

    cookieAcceptHandler();
    closeCookieAcceptNotice();
});
