import IMask from 'imask';

export class Mask {
    constructor() {
        this.masks = [
            {
                selector: '.mask-phone',
                options: {
                    mask: '+{7} (000) 000-00-00',
                },
            },
            {
                selector: '.mask-invoice',
                options: {
                    mask: '00000-0000000000',
                },
            },
        ];
        this.maskInit();
    }

    maskInit(targetNode = false) {
        this.masks.forEach((itemMask) => {
            const node = targetNode || document;
            const fields = node.querySelectorAll(itemMask.selector);

            fields.forEach((itemField) => {
                IMask(itemField, itemMask.options);
            });
        });
    }
}
