import './assets/assets';

document.addEventListener('DOMContentLoaded', async () => {
    const order = document.getElementById('order');

    if (order) {
        if (__IS_DEV__) {
            order.insertAdjacentHTML(
                'beforeend',
                `<object data="http://localhost:5173/" width="100%" height="4500"></object>`
            );
        } else {
            await import('./order/order');
        }
    }
});
